html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-control {
  font-size: 0.9rem;
}

.dropdown-item {
  font-size: 0.9rem;
}

.inputMobilePhone {
  min-width: 428px;padding-top: 8px !important;padding-bottom: 8px !important;border-radius: 10px !important;border-color: rgb(151, 151, 151) !important;
}